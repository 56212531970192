.adminPage {
    text-align: center;
    padding: 20px;
  }
  
  .scannerContainer {
    margin-bottom: 20px;
  }
  
  .payment-status {
    font-size: 16px;
    margin-top: 20px;
  }
  
  .payment-status.success {
    color: green;
  }
  
  .payment-status.error {
    color: red;
  }
  
  .scannedResult {
    margin-top: 20px;
    font-weight: bold;
  }
  