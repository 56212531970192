.tickets {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f6f2;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .tickets h2 {
    text-align: center;
    color: #159FB3;
  }
  
  form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  form select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .ticket-summary {
    text-align: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .ticket-summary h3 {
    color: #159FB3;
  }
  .buy-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #159FB3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .buy-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  /* Add this to your existing Tickets.css */
.email-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 400px;
  z-index: 1000; /* Make sure it appears above other content */
  text-align: center;
}

.email-popup h3 {
  color: #159FB3;
  margin-bottom: 15px;
}

.email-popup input {
  width: 80%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.email-popup button {
  padding: 10px 20px;
  background-color: #159FB3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.email-popup button:hover {
  background-color: #0ea2b1;
}

.email-popup button:focus {
  outline: none;
}

.payment-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 300px;
  border-radius: 10px;
}

.payment-popup h3 {
  text-align: center;
}

  
  
  