/* PaystackPayment.css */

/* Container for error popup */
.error-popup {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .error-popup button {
    background-color: #721c24;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .error-popup button:hover {
    background-color: #c82333;
  }
  
  /* Styling the Paystack button */
  .paystack-button {
    display: inline-block;
    background-color: #159FB3;  /* Use a blue tone for the button */
    color: #fff;
    padding: 15px 30px;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .paystack-button:hover {
    background-color: #0EA2B1;  /* Lighter shade of blue on hover */
    transform: translateY(-2px);
  }
  
  .paystack-button:active {
    background-color: #0A8C98;  /* Darker shade on click */
    transform: translateY(0);
  }

  .ticket-details {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f8f9fa;
    width: 80%;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .ticket-details p {
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
  }
  
  .ticket-details button {
    background-color: #0EA2B1; /* Color from your palette */
    color: white;
    border: none;
    margin: 10px 0px;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .ticket-details button:hover {
    background-color: #159FB3; /* Hover color */
    transform: scale(1.05);
  }
  
  .ticket-details button:active {
    background-color: #0EA2B1; /* Darker shade on click */
    transform: scale(0.98);
  }
  
  .payment-status {
    font-size: 18px;
    color: #4CAF50;
    margin: 20px 0;
  }
  