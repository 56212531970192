/* General Styles */
/*  */
.mainContainer{
  margin: 0;
  font-family: 'Arial', sans-serif;
  background: linear-gradient(135deg, #c1eaf8, #f8f6f2);
  color: #333;
  min-height: 100vh;
}
.mainContainer.no-scroll {
  overflow: hidden; /* Disable scrolling */
}

.appNavBar{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.routeContainer{
  padding-top: 120px;
}

a {
  text-decoration: none;
  color: #159fb3;
  transition: color 0.3s ease;
}

a:hover {
  color: #0ea2b1;
}

/* Footer Styles */
.footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 20px;
  margin-top: 50px;
}

.footer a {
  color: #f69f71;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: #159fb3;
  text-decoration: underline;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-size: 0.9em;
}

/* Event Details Styles */
.event-details {
  padding: 40px;
  max-width: 900px;
  margin: auto;
  background: white;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.event-details h1 {
  font-size: 2.5em;
  color: #159fb3;
  margin-bottom: 20px;
}

.event-details p {
  margin-bottom: 15px;
  line-height: 1.8;
}



/* Form Styles */
form {
  background: white;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

form label {
  font-weight: bold;
  margin-bottom: 10px;
}

form input,
form select,
form button {
  font-size: 1em;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

form input:focus,
form select:focus {
  border-color: #159fb3;
  outline: none;
  box-shadow: 0 0 5px rgba(21, 159, 179, 0.5);
}

.imageContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Make sure it stays on top */
}

.introImage {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}

.cancelButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
  z-index: 1000;
}

/* Ensure proper scaling for smaller devices */

@media only screen and (min-width: 320px){
  .mainContainer{
    overflow-x: hidden !important;
  }
  .cancelButton {
    background: red !important;
  }
}
@media (max-width: 768px) {
  .event-details {
    padding: 20px;
    font-size: 0.9em; /* Adjust font size */
  }

  form {
    padding: 20px;
  }

  .footer {
    font-size: 0.8em; /* Make footer text smaller */
  }
}


/* .appFooter{
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
} */
